import React, { useEffect } from 'react';
import Layout from '@components/Layout/Layout';
import DownloadAppButton from '@src/components/DownloadAppButton/DownloadAppButton';
import Container from '@src/components/Container/Container';
import { isMobile } from 'react-device-detect';
import { navigate } from 'gatsby-plugin-intl';
import * as styles from './mobile-only.module.scss'

const EmailVerify = ({ ...restProps }) => {
  const verificationCode = restProps['*'];
  useEffect(() => {
    if (isMobile) {
      const url = `rockerapp://email-verify/${verificationCode}`;
      window.location.href = url;
    } else {
      navigate("/mobile-only")
    }
  }, [verificationCode, isMobile]);

  return (
    <Layout path="/email-verify">
      <Container centered={true}>
      <div className={styles.textContainer}>
          <p className={styles.heading}>Länken ska öppnas på din mobil</p>
          <DownloadAppButton />
        </div>
      </Container>
    </Layout>
  );
};

export default EmailVerify;
